var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "showHeader" } },
    [
      _c(
        "a-layout-header",
        {
          class: [
            _vm.fixedHeader && "ant-header-fixedHeader",
            _vm.sidebarOpened
              ? "ant-header-side-opened"
              : "ant-header-side-closed"
          ],
          style: { padding: "0" }
        },
        [
          _vm.mode === "sidemenu"
            ? _c(
                "div",
                {
                  staticClass: "header",
                  staticStyle: {
                    display: "flex",
                    "justify-content": "space-between"
                  }
                },
                [
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c("a-icon", {
                        staticClass: "trigger",
                        staticStyle: { display: "flex" },
                        attrs: {
                          type: _vm.collapsed ? "menu-unfold" : "menu-fold"
                        },
                        on: { click: _vm.toggle }
                      }),
                      _c("page-header")
                    ],
                    1
                  ),
                  _c(
                    "div",
                    {
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [_c("lang-select"), _vm._v(" "), _c("user-menu")],
                    1
                  )
                ]
              )
            : _c("div", { class: ["top-nav-header-index", _vm.theme] }, [
                _c("div", { staticClass: "header-index-wide" }, [
                  _c(
                    "div",
                    { staticClass: "header-index-left" },
                    [
                      _c("logo", {
                        staticClass: "top-nav-header",
                        attrs: {
                          title: _vm.$t("siteName"),
                          "show-title": _vm.device !== "mobile"
                        }
                      }),
                      _vm.device !== "mobile"
                        ? _c("s-menu", {
                            attrs: {
                              mode: "horizontal",
                              menu: _vm.menus,
                              theme: _vm.theme
                            }
                          })
                        : _c("a-icon", {
                            staticClass: "trigger",
                            attrs: {
                              type: _vm.collapsed ? "menu-fold" : "menu-unfold"
                            },
                            on: { click: _vm.toggle }
                          })
                    ],
                    1
                  ),
                  _c("span", { staticStyle: { flex: "1" } }),
                  _c(
                    "div",
                    { staticStyle: { display: "flex" } },
                    [_c("user-menu")],
                    1
                  )
                ])
              ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }