//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UserMenu from '../tools/UserMenu';
import SMenu from '../Menu/';
import Logo from '../tools/Logo';
import LangSelect from '../tools/LangSelect';
import { mixin } from '@/utils/mixin';
import PageHeader from '@/components/PageHeader';

export default {
  name: 'GlobalHeader',
  components: {
    UserMenu,
    SMenu,
    Logo,
    LangSelect,
    PageHeader
  },
  mixins: [mixin],
  props: {
    mode: {
      type: String,
      default: 'sidemenu' // sidemenu, topmenu
    },
    menus: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    },
    device: {
      type: String,
      required: false,
      default: 'desktop'
    }
  },
  methods: {
    toggle() {
      this.$emit('toggle');
    }
  }
};
