import Vue from 'vue';
import router from './router';
import store from './store';
import i18n from '@/locales';

import NProgress from 'nprogress'; // progress bar
import '@/components/NProgress/nprogress.less'; // progress bar custom style

import notification from 'ant-design-vue/es/notification';
import { setDocumentTitle, domTitle } from '@/utils/domUtil';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import message from 'ant-design-vue/es/message';
import socket from '@/plugins/socket';
NProgress.configure({ showSpinner: false }); // NProgress Configuration

const whiteList = ['login', 'forgot', 'register', 'request', 'oauth-login', '404']; // no redirect whitelist
const mustBeAuthenticate = ['/user/login', '/user/forgot', '/user/request'];
const defaultRoutePath = '/';
const defaultRouteLogin = '/user/login';

router.beforeEach((to, from, next) => {
  message.destroy();
  notification.destroy();

  NProgress.start(); // start progress bar
  // to.matched[to.matched.length - 1] is itself, to.matched[to.matched.length - 2] is parent
  const metaTitle = to.meta.title || to.matched[to.matched.length - 2]?.meta?.title || '';
  setDocumentTitle(`${i18n.t(metaTitle)} | ${i18n.t(domTitle)}`);
  if (Vue.ls.get(ACCESS_TOKEN)) {
    /* has token */
    if (mustBeAuthenticate.includes(to.path)) {
      next({ path: defaultRoutePath });
      NProgress.done();
    } else {
      if (store.getters.roles.length === 0) {
        const messageCopy = message;
        store
          .dispatch('user/GetInfo')
          .then(({ roles, user }) => {
            if (user.company.is_block) {
              return next({ path: '/block' });
            }
            const companyType = user.company.company_type;
            const companyTypeList = (process.env.VUE_APP_COMPANY_TYPE as string).split(' ');

            if (roles.includes('admin') || !companyTypeList.includes(companyType)) {
              messageCopy.error(i18n.t('Login failed'), 3);
              store.dispatch('user/Logout').then(() => {
                NProgress.done();
              });
            } else {
              store.dispatch('GenerateRoutes', { roles }).then(async () => {
                router.addRoutes(store.getters.addRouters);
                const redirect = decodeURIComponent(from.query.redirect || to.path);
                if (to.path === redirect) {
                  // hack ,set the replace: true so the navigation will not leave a history record
                  next({ ...to, replace: true });
                } else {
                  next({ path: redirect });
                }
              });
              socket();
            }
          })
          .catch(() => {
            notification.error({
              message: 'Error',
              description: 'Request for user information failed, please try again'
            });
            store.dispatch('user/Logout').then(() => {
              next({ path: defaultRouteLogin, query: { redirect: to.fullPath } });
            });
          });
      } else {
        next();
      }
    }
  } else {
    store.dispatch('InitializerBeforeLogin').then(() => console.log('Initializer successful'));
    if (whiteList.includes(to.name)) {
      next();
    } else {
      next({ path: defaultRouteLogin, query: { redirect: to.fullPath } });
      NProgress.done(); // if current page is login will not trigger afterEach hook, so manually handle it
    }
  }
});

router.afterEach(() => {
  NProgress.done(); // finish progress bar
});
