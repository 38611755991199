import '@ag-grid-community/styles/ag-grid.css';
// ag-theme-alpine should be imported after ag-grid.css
import '@ag-grid-community/styles/ag-theme-alpine.css';

import '@ag-grid-community/client-side-row-model';
import '@ag-grid-community/infinite-row-model';
import '@ag-grid-community/csv-export';

import Vue from 'vue';
import { SGrid } from '@/components/Grid';

Vue.component('SGrid', SGrid);
