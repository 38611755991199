import axios from 'axios';
import { Notify } from 'quasar';

const notifyError = (message) => {
  Notify.create({ message, color: 'negative', icon: 'report_problem' })
}

const lambdaService = axios.create({
  baseURL: process.env.VUE_APP_SOUND_API,
  timeout: 30000,
  headers: { 'Content-Type': 'application/json' }
});

const err = (error) => {
  if (!error.response) {
    notifyError(error.message)
    return Promise.reject(new Error('Network error'));
  } else {
    const data = error.response.data;
    if (error.response.status === 403) {
      notifyError(data.message)
    }
    if (error.response.status === 401) {
      notifyError('Authorization verification failed')
    }
    return Promise.reject(error.response.data.error);
  }
};

// request interceptor
lambdaService.interceptors.request.use(config => {  
  config.headers['x-api-key'] = process.env.VUE_APP_SOUND_API_KEY
  // config.headers['Access-Control-Max-Age'] = 86400
  return config
}, err)
// response interceptor
lambdaService.interceptors.response.use(response => {
  if (response.status === 204) {
    return {};
  }
  const { data } = response;
  if (data.status === 'OK') {
    return data.data;
  }
  if (data.status === 'NG') {
    throw data.error;
  }
  // 'Server error, please try again later.'
  throw new Error('Server error');
}, err);

// export default boot(({ app }) => {
//   app.config.globalProperties.$axios = axios;
//   app.config.globalProperties.$api = service;
// });

export { lambdaService };
