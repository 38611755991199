export default {
	"-1 ": "ファイルのオープンに失敗した",
	"-2 ": "ファイルの作成に失敗した",
	"-3 ": "ファイルの読込みに失敗した",
	"-4 ": "ファイルの書込みに失敗した",
	"-5 ": "作業メモリの確保に失敗した",
	"-6 ": "シンボルデータが大き過ぎる",
	"-7 ": "ビットマップイメージフォーマットが不正である",
	"-8 ": "サポート外のビットマップイメージフォーマットである",
	"-9 ": "ビットマップタイプが違う",
	"-10": " ビットマップバッファパラメータが不正である",
	"-11": " シンボルイメージが小さすぎる",
	"-12": " T-マークの解析に失敗した",
	"-13": " シンボルブロック数が不正である",
	"-14": " 上下左右のブロック数が一致しない",
	"-15": " 回転したシンボルとして認識できない",
	"-16": " シンボルヘッダデータがない",
	"-17": " シンボルヘッダの取得、エラー訂正に失敗した",
	"-18": " ボディデータの取得、エラー訂正に失敗した",
	"-19": " シンボルヘッダのタイプ値が不正である",
	"-20": " シンボルヘッダの revision 値が不正である",
	"-21": " シンボルヘッダの圧縮情報値が不正である",
	"-22": " シンボルヘッダのライセンス ID 情報が不正である",
	"-23": " シンボルヘッダのデータワード数が不正である",
	"-24": " デコード出力先/作業バッファをオーバした",
	"-25": " ボディデータが不正で、ボディデータ外参照となった",
	"-26": " 不正な圧縮データで解凍に失敗した",
	"-27": " 解凍結果のデータが不正で、サブデータの解凍に失敗した",
	"-28": " 不明なテキスト種別の多国語テキストを検出した",
	"-29": " サブデータが不正で解凍に失敗した",
	"-30": " 多国語テキストのデータヘッダ不正でテキスト外アクセスとなった",
	"-31": " シンボルのサイズパラメータが不正である",
	"-32": " シンボルのシンボル種別パラメータが不正である",
	"-33": " シンボルのエラー訂正強度パラメータが不正である",
	"-34": " 印刷密度パラメータが不正である",
	"-35": " セルサイズパラメータが不正である",
	"-36": " セル形状パラメータが不正である",
	"-37": " 黒輝度パラメータが不正である",
	"-38": " データ量が多く、指定のシンボルサイズのシンボルに格納できなかった",
	"-50": " 言語名⇒テキスト種別対応定義ファイルが不正である",
	"-51": " 言語名⇒テキスト種別対応定義の言語名指定が不正である",
	"-52": " 言語名⇒テキスト種別対応定義のテキスト種別指定が不正である",
	"-53": " 言語名⇒テキスト種別対応定義のテキスト種別指定の後に不正なデータがある",
	"-54": " 言語名⇒テキスト種別対応定義最大数を超えている",
	"-55": " ASCII タグ付多国語テキスト記述のコメント記述に不正な文字がある、または、コメントの中でファイル終端を検出した",
	"-56": " ASCII タグ付多国語テキスト記述のコメント行と同じ行内に空白、タブ以外のデータが存在する",
	"-57": " ASCII タグ付多国語テキスト記述で、<tag ..>なしで、テキストデータを検出した",
	"-58": " ASCII タグ付多国語テキスト記述で、<tag ..>以外のタグを検出した",
	"-59": " ASCII タグ付多国語テキスト記述の<tag ...>指定の途中でファイル終端を検出した",
	"-60": " ASCII タグ付多国語テキスト記述の<tag ...>指定の lang 属性指定が不正である",
	"-61": " ASCII タグ付多国語テキスト記述の<tag ...>指定の size 属性指定が不正である",
	"-62": " ASCII タグ付多国語テキスト記述の<tag ...>指定の type 属性指定が不正である",
	"-63": " ASCII タグ付多国語テキスト記述の<tag ...>指定の del_nl 属性指定が不正である",
	"-64": " ASCII タグ付多国語テキスト記述の<tag ...>指定で、不正な属性指定を検出した",
	"-65": " ASCII タグ付多国語テキスト記述の<tag ...>指定で、lang 属性指定がなかった",
	"-66": " ASCII タグ付多国語テキスト記述の<tag ..>行と同じ行内に空白、タブ以外のデータが存在する",
	"-67": " ASCII タグ付多国語テキスト記述において、size 属性で指定したサイズ分のデータが存在しない",
	"-68": " ASCII タグ付多国語テキスト記述において、size 属性で指定したサイズ分のテキストの直後が行末、行同でもなく、行の途中となっている",
	"-69": " ASCII タグ付多国語テキスト記述において、対応する終端タグ</tag>が見つからなかった",
	"-70": " ASCII タグ付多国語テキスト記述のテキストデータが空である",
	"-71": " ASCII タグ付多国語テキスト記述のテキストデータに不正な文字がある",
	"-72": " ASCII タグ付多国語テキスト記述からの変換で、変換先のバッファをオーバした",
	"-73": " ASCII タグ付多国語テキスト記述の<tag ...>指定の hex 属性指定が不正である",
	"-74": " ASCII タグ付多国語テキスト記述の<tag ...>指定の attr 属性指定が不正である",
	"-99": " 未実施の処理である",
	"-100": "データに誤りがあります。",
	"request entity too large": "文字数が多すぎます。コードサイズを変更して再度お試しください。"
}