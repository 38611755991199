var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-dropdown",
    { attrs: { trigger: ["click"] } },
    [
      _c(
        "div",
        {
          staticClass: "ant-dropdown-link",
          staticStyle: {
            display: "flex",
            "align-items": "center",
            cursor: "pointer"
          },
          on: {
            click: function(e) {
              return e.preventDefault()
            }
          }
        },
        [
          [
            _vm.lang.key === "en"
              ? _c("img", {
                  staticClass: "img-flag",
                  attrs: {
                    src: require("@/assets/icons/en.svg"),
                    alt: _vm.lang.name
                  }
                })
              : _vm._e(),
            _vm.lang.key === "ja"
              ? _c("img", {
                  staticClass: "img-flag",
                  attrs: {
                    src: require("@/assets/icons/ja.svg"),
                    alt: _vm.lang.name
                  }
                })
              : _vm._e(),
            _vm.showText
              ? _c("span", { staticStyle: { "margin-left": "8px" } }, [
                  _vm._v(" " + _vm._s(_vm.lang.name))
                ])
              : _vm._e()
          ],
          _c("a-icon", {
            staticStyle: { "font-size": "16px" },
            attrs: { type: "down" }
          })
        ],
        2
      ),
      _c(
        "a-menu",
        {
          staticStyle: { "min-width": "150px" },
          attrs: { slot: "overlay" },
          on: { click: _vm.switchLang },
          slot: "overlay"
        },
        _vm._l(_vm.supportLang, function(lang, key) {
          return _c("a-menu-item", { key: key }, [
            lang.key === "en"
              ? _c("img", {
                  staticClass: "img-flag",
                  attrs: {
                    src: require("@/assets/icons/en.svg"),
                    alt: lang.name
                  }
                })
              : _vm._e(),
            lang.key === "ja"
              ? _c("img", {
                  staticClass: "img-flag",
                  attrs: {
                    src: require("@/assets/icons/ja.svg"),
                    alt: lang.name
                  }
                })
              : _vm._e(),
            _c("span", { staticStyle: { "padding-left": "8px" } }, [
              _vm._v(_vm._s(lang.name))
            ])
          ])
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }