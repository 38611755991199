import Vue from 'vue'
const initialState = () => ({
  entities: {},
  ids: []
})

const store = {
  namespaced: true,
  state: {
    ...initialState()
  },
  getters: {
    ids: state => state.ids,
    codes: (state, getters) => {
      return getters.ids.map(id => state.entities[id]) || []
    },
    codeById: state => id => state.entities[id]
  },
  actions: {
    addCode({ commit }, payload) {
      return new Promise((resolve) => {
        commit('SET', payload)
        resolve(1)
      })
    },
    removeCode({ commit }, payload) {
      return new Promise((resolve) => {
        commit('REMOVE', payload)
        resolve(1)
      })
    },
    loadCodes({ commit }, payload) {
      return new Promise((resolve) => {
        commit('SET', payload)
        resolve(1)
      })
    },
    updateCode({ commit }, payload) {
      return new Promise((resolve) => {
        commit('UPDATE', payload)
        resolve(1)
      })
    },
    reset({ commit }) {
      return new Promise((resolve) => {
        commit('RESET')
        resolve(1)
      })
    }
  },
  mutations: {
    SET: (state, { payload }) => {
      if (Array.isArray(payload)) {
        let newIds = [...state.ids]
        const newEntities = Object.assign({}, state.entities)

        payload.forEach((values) => {
          const id = values.id
          newIds = [...newIds, id]
          newEntities[id] = Object.assign({}, newEntities[id], values)
        })

        const ids = Array.from(new Set(newIds))
        const entities = Object.assign({}, newEntities)

        Vue.set(state, 'ids', ids)
        Vue.set(state, 'entities', Object.assign({}, entities))
      } else {
        const id = payload.id
        const newIds = Array.from(new Set([id, ...state.ids]))
        const newEntities = Object.assign({}, state.entities, {
          [id]: Object.assign({}, state.entities[id], payload)
        })
        Vue.set(state, 'ids', newIds)
        Vue.set(state, 'entities', newEntities)
      }
    },
    REMOVE: (state, payload) => {
      const newEntities = Object.assign({}, state.entities)
      let newIds = [...state.ids]

      if (payload.id in newEntities) {
        delete newEntities[payload.id]
      }
      newIds = newIds.filter(id => id in newEntities)
      Vue.set(state, 'ids', newIds)
      Vue.set(state, 'entities', newEntities)
    },
    UPDATE: (state, payload) => {
      state.entities[payload.id] = { ...payload }
    },
    UPDATE_CODE_POSITION(state, { enterIndex, endIndex }) {
      let newIds = [...state.ids].filter((item, index) => index !== enterIndex)
      newIds.splice(endIndex, 0, state.ids[enterIndex]);
      Vue.set(state, 'ids', newIds)
    },
    UPDATE_ORDER(state, { id, order_by }) {
      // this action is not affected by the order array, it mean the UI is not re-rendered
      state.entities[id].order_by = order_by;
    },
    RESET: (state) => {
      Object.assign(state, initialState())
    }
  }
}

export default store
