//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex';
export default {
  name: 'PageView',
  components: {},
  props: {
    avatar: {
      type: String,
      default: null
    },
    title: {
      type: [String, Boolean],
      default: true
    },
    logo: {
      type: String,
      default: null
    },
    directTabs: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      pageTitle: null,
      description: null,
      linkList: [],
      extraImage: '',
      search: false,
      tabs: {},
      pageActions: []
    };
  },
  computed: {
    ...mapState({
      multiTab: state => state.app.multiTab
    })
  },
  mounted() {
    this.tabs = this.directTabs;
    this.getPageMeta();
  },
  updated() {
    this.getPageMeta();
  },
  methods: {
    getPageMeta() {
      // eslint-disable-next-line
      this.pageTitle =
        typeof this.title === 'string' || !this.title ? this.title : this.$route.meta.title;

      const content = this.$refs.content;
      if (content) {
        if (content.pageMeta) {
          Object.assign(this, content.pageMeta);
        } else {
          this.description = content.description;
          this.linkList = content.linkList;
          this.extraImage = content.extraImage;
          this.search = content.search === true;
          this.tabs = content.tabs;
          this.pageActions = content.pageActions;
        }
      }
    }
  }
};
