import Vue from 'vue';
import { univoiceApi } from '@/api';

const initialState = () => ({
  entities: {},
  ids: [],
  originData: []
});

const store = {
  namespaced: true,
  state: {
    ...initialState()
  },
  getters: {
    ids: state => state.ids,
    categories: (state, getters) => {
      return getters.ids.map(id => state.entities[id]) || [];
    },
    // this is the category hierarchy data for the tree component, which keep server fields
    originCategories: state => state.originData,
    categoryById: state => id => state.entities[id]
  },
  actions: {
    async fetchCategories({ dispatch, commit }, pageId) {
      const result = await univoiceApi.page.getCategories(pageId);
      const formatCategoryItem = category => {
        const { id, name, children } = category;
        let newCategory = { key: id, value: id, label: name, children: [] };
        if (children && children.length > 0) {
          children.forEach(item => {
            newCategory.children.push(formatCategoryItem(item));
          });
        }
        return newCategory;
      };
      let categories = [];
      result.forEach(item => {
        categories.push(formatCategoryItem(item));
      });
      dispatch('loadCategories', { payload: categories });
      commit('SET_ORIGIN', { payload: result });
    },
    loadCategories({ commit }, payload) {
      return new Promise(resolve => {
        commit('SET', payload);
        resolve(1);
      });
    },
    updateCategory({ commit }, payload) {
      return new Promise(resolve => {
        commit('UPDATE', payload);
        resolve(1);
      });
    }
  },
  mutations: {
    SET: (state, { payload }) => {
      if (Array.isArray(payload)) {
        let newIds = [];
        let newEntities = {};

        payload.forEach(values => {
          const id = values.key;
          newIds = [...newIds, id];
          newEntities[id] = Object.assign({}, newEntities[id], values);
        });

        const ids = Array.from(new Set(newIds));
        const entities = Object.assign({}, newEntities);

        Vue.set(state, 'ids', ids);
        Vue.set(state, 'entities', Object.assign({}, entities));
      } else {
        const id = payload.key;
        const newIds = Array.from(new Set([id, ...state.ids]));
        const newEntities = Object.assign({}, state.entities, {
          [id]: Object.assign({}, state.entities[id], payload)
        });
        Vue.set(state, 'ids', newIds);
        Vue.set(state, 'entities', newEntities);
      }
    },
    SET_ORIGIN: (state, { payload }) => {
      Vue.set(state, 'originData', payload);
    }
  }
};

export default store;
