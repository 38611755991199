//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { triggerWindowResizeEvent } from '@/utils/util';
import { mapActions, mapState } from 'vuex';
import { mixin, mixinDevice } from '@/utils/mixin';
import config from '@/config/defaultSettings';

import RouteView from './RouteView';
import SideMenu from '@/components/Menu/SideMenu';
import GlobalHeader from '@/components/GlobalHeader';

export default {
  name: 'BasicLayout',
  mixins: [mixin, mixinDevice],
  components: { RouteView, SideMenu, GlobalHeader },
  data() {
    return {
      production: config.production,
      collapsed: false,
      menus: []
    };
  },
  computed: {
    ...mapState({
      mainMenu: state => state.permission.addRouters
    }),
    contentPaddingLeft() {
      if (!this.fixSidebar || this.isMobile()) {
        return '0';
      }
      if (this.sidebarOpened) {
        return '256px';
      }
      return '80px';
    }
  },
  watch: {
    sidebarOpened(val) {
      this.collapsed = !val;
    }
  },
  created() {
    // Initial data
    this.Initializer().then(() => console.log('Initializer successful'));

    this.menus = this.mainMenu.find(item => item.path === '/').children;
    this.collapsed = !this.sidebarOpened;
  },
  mounted() {
    const userAgent = navigator.userAgent;
    if (userAgent.indexOf('Edge') > -1) {
      this.$nextTick(() => {
        this.collapsed = !this.collapsed;
        setTimeout(() => {
          this.collapsed = !this.collapsed;
        }, 16);
      });
    }
  },
  methods: {
    ...mapActions(['Initializer', 'setSidebar']),
    toggle() {
      this.collapsed = !this.collapsed;
      this.setSidebar(!this.collapsed);
      triggerWindowResizeEvent();
    },
    paddingCalc() {
      let left = '';
      if (this.sidebarOpened) {
        left = this.isDesktop() ? '256px' : '80px';
      } else {
        left = (this.isMobile() && '0') || (this.fixSidebar && '80px') || '0';
      }
      return left;
    },
    menuSelect() {},
    drawerClose() {
      this.collapsed = false;
    }
  }
};
