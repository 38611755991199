import Vue from 'vue';

import './styles/quasar.scss';
import 'quasar/dist/quasar.ie.polyfills';
import '@quasar/extras/material-icons/material-icons.css';
import { Quasar } from 'quasar';

Vue.use(Quasar, {
  config: {
    extras: [
      // ctx.theme.mat ? 'roboto-font' : null,
      // 'roboto-font',
      // "fontawesome-v5",
      // "material-icons" // optional, you are not bound to it
      // 'ionicons-v4',
      // 'mdi-v3 ',
      // 'fontawesome-v5'
    ]
  },
  components: {
    /* not needed if importStrategy is not 'manual' */
  },
  directives: {
    /* not needed if importStrategy is not 'manual' */
  },
  plugins: {}
});
