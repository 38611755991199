export default {
  avatar: state => state.info.phone || "/img/no-image.png",
  nickname: state => state.info.email,
  isAuthenticated: state => !state.token,
  token: state => state.token,
  userInfo: state => state.info,
  roles: state => state.roles,
  masterData: state => state.masterData || {},
  isSupportedRagt: state => state.info?.company?.integrations?.find(item => item.title === 'RAGT')
};
