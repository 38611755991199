//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mixin as langMixin } from '@/mixins/i18n-mixin';

const supportLang = {
  en: {
    name: 'English',
    key: 'en'
  },
  ja: {
    name: '日本語',
    key: 'ja'
  }
};

export default {
  name: 'LangSelect',
  mixins: [langMixin],
  props: {
    showText: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      supportLang,
      fontFamily: {
        en: `'Roboto', sans-serif`,
        ja: `'Noto Sans JP', Helvetica, arial, sans-serif`
      }
    };
  },
  computed: {
    lang() {
      return supportLang[this.currentLang];
    }
  },
  methods: {
    switchLang(row) {
      this.setLang(row.key);
      this.$ls.set('lang', row.key);
      this.setGlobalStyle({
        fontFamily: this.fontFamily[row.key]
      });
      this.$ls.set('globalStyle', {
        fontFamily: this.fontFamily[row.key]
      });
    }
  }
};
