import { asyncRouterMap } from "@/config/router.config";

function hasPermission(permission, route) {
  // if (route.meta && route.meta.permission) {
  //   let flag = false;
  //   for (let i = 0, len = permission.length; i < len; i++) {
  //     flag = route.meta.permission.includes(permission[i]);
  //     if (flag) {
  //       return true;
  //     }
  //   }
  //   return false;
  // }
  // return true;

  if (permission) {
    if (route.meta && route.meta.permission) {
      let flag = false;
      for (let i = 0, len = route.meta.permission.length; i < len; i++) {
        flag = permission.includes(route.meta.permission[i]);
        if (flag) {
          return true;
        }
      }
      return false;
    }
    return true;
  } 
  return false;
}

function filterAsyncRouter(routerMap, roles) {
  const accessedRouters = routerMap.filter(route => {
    if (hasPermission(roles, route)) {
      if (route.children && route.children.length) {
        route.children = filterAsyncRouter(route.children, roles);
      }
      return true;
    }
    return false;
  });
  return accessedRouters;
}

export default {
  GenerateRoutes({ commit }, data) { 
    return new Promise(resolve => {
      const { roles } = data;
      const accessedRouters = filterAsyncRouter(asyncRouterMap, roles);
      commit("SET_ROUTERS", accessedRouters);
      resolve(1);
    });
  }
};
