import { mapState } from 'vuex';

const mixin = {
  computed: {
    ...mapState({
      currentLang: state => state.i18n.lang,
      currentGlobalStyle: state => state.i18n.globalStyle
    })
  },
  methods: {
    setLang(lang) {
      this.$store.dispatch('SetLang', lang);
    },
    setGlobalStyle(style) {
      this.$store.dispatch('SetGlobalStyle', style);
    }
  }
};

export { mixin };
