import VueSocketIO from 'vue-socket.io';
import io from 'socket.io-client';
import Vue from 'vue';
import { ACCESS_TOKEN } from '@/store/mutation-types';

export default function socket() {
  // socketIO
  const token = Vue.ls.get(ACCESS_TOKEN);
  Vue.use(
    new VueSocketIO({
      connection: io(process.env.VUE_APP_SERVER_BASE_URL, {
      // connection: io('http://localhost:3000', {
        auth: {
          token
        },
        transports: ['websocket']
      })
    })
  );
}
