import Vue from 'vue';
import VueChartkick from 'vue-chartkick';
import Chart from 'chart.js';

Chart.pluginService.register({
  afterDraw: function(chartInstance) {
    if (chartInstance.config.options.showValue) {
      var ctx = chartInstance.chart.ctx;

      // render the value of the chart above the bar
      ctx.font = Chart.helpers.fontString(
        Chart.defaults.global.defaultFontSize,
        'normal',
        Chart.defaults.global.defaultFontFamily
      );
      ctx.textAlign = 'center';
      ctx.textBaseline = 'bottom';

      chartInstance.data.datasets.forEach(function(dataset) {
        for (var i = 0; i < dataset.data.length; i++) {
          var model = dataset._meta[Object.keys(dataset._meta)[0]].data[i]._model;
          const value = dataset.data[i] || 0;
          if (+value > 0) {
            const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,');
            ctx.fillText(`${intPartFormat}`, model.x, model.y - 2);
          }
        }
      });
    }
  }
});
Vue.use(VueChartkick, { adapter: Chart });
// Vue.use(VueChartkick.use(Chart));
