import Vue from "vue";
import Vuex from "vuex";

import app from "./app";
import user from "./user";
import message from "./message";
import project from "./project";
import page from "./page";
import billing from './billing';
import code from "./code";
import i18n from "./i18n";
import permission from "./permission";
import resource from "./data";
import category from './category'
import dictionary from './dictionary'

import getters from "./getters";
import actions from "./actions";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    app,
    user,
    project,
    page,
    message,
    billing,
    code,
    permission,
    resource,
    i18n,
    category,
    dictionary
  },
  getters,
  actions
});
