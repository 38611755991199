export default {
  pref: state => state.pref,
  prefStates: state => state.stateCity.ids,
  getCitiesByState: state => id => state.stateCity.entities[id],
  prefData: state => {
    const pref = state.pref || {};
    const res = {};
    Object.keys(pref).forEach(key => {
      const obj = pref[key];
      res[key] = obj.prefName;

      obj.cities.forEach(p => {
        const k = `${p.cityCode}`;
        res[k] = p.cityName;
      });
    });

    return res;
  },

  cityCodeToName: (state: any, getters) => code => {
    return getters.prefData[code];
  },

  newCityCodeToName: (state: any) => code => {
    return state.flatEntities[code];
  },

  treeDataStates: (state: any) => {
    return Object.keys(state.newPref)
      .map(key => {
        const pref = state.newPref[key];

        const children = Object.keys(pref.cities).map(childKey => {
          const city = pref.cities[childKey];
          const k = `${city.cityCode}`;
          if (city.bigCityFlag != '2') {
            return {
              key: `city-${k}`,
              value: `city-${k}`,
              title: city.cityName
            };
          } else {
            const bigCityChild = Object.keys(city.wards).map(bigCityKey => {
              const childBigCity = city.wards[bigCityKey];
              let k = '';
              if (childBigCity.bigCityFlag == '1') {
                k = `city-${childBigCity.cityCode}`;
              } else {
                k = `ward-${childBigCity.cityCode}`;
              }
              return {
                key: k,
                value: k,
                title: childBigCity.cityName
              };
            });
            return {
              key: `city-${k}`,
              value: `city-${k}`,
              title: city.cityName,
              children: bigCityChild
            };
          }
        });
        return {
          key: `pref-${key}`,
          value: `pref-${key}`,
          title: pref.prefName,
          children
        };
      })
      .sort((a, b) => a.key.localeCompare(b.key));
  }
};
