/**
 * Vue i18n loader
 * created by @musnow
 * https://github.com/musnow
 */
import Vue from "vue";
import VueI18n from "vue-i18n";
// default language
import jaJP from "./lang/ja";

Vue.use(VueI18n);

export const defaultLang = "ja";

const messages = {
  "ja": {
    ...jaJP
  }
};

const i18n = new VueI18n({
  locale: defaultLang,
  fallbackLocale: defaultLang,
  messages
});

export default i18n;

const loadedLanguages = [defaultLang];

function setI18nLanguage(lang: any) {
  i18n.locale = lang;
  const html  = document.querySelector("html");
  if(html){
    html.setAttribute("lang", lang);
  }
  return lang;
}

export function loadLanguageAsync(lang = defaultLang) {
  return new Promise(resolve => {
    if (i18n.locale !== lang) {
      if (!loadedLanguages.includes(lang)) {
        return import(/* webpackChunkName: "lang-[request]" */ `./lang/${lang}`).then(msg => {
          i18n.setLocaleMessage(lang, msg.default);
          loadedLanguages.push(lang);
          return setI18nLanguage(lang);
        });
      }
      return resolve(setI18nLanguage(lang));
    }
    return resolve(lang);
  });
}
