
export default {
  name: 'RouteView',
  props: {
    keepAlive: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {};
  },
  render() {
    const {
      $route: { meta },
      $store: { getters }
    } = this;
    const inKeep = (
      <keep-alive>
        <router-view key={this.$route.fullPath} />
      </keep-alive>
    );
    const notKeep = <router-view key={this.$route.fullPath} />;
    if (!getters.multiTab && !meta.keepAlive) {
      return notKeep;
    }
    return this.keepAlive || getters.multiTab || meta.keepAlive ? inKeep : notKeep;
  }
};
