/* eslint-disable no-undef */
export default {
  primaryColor: "#13C2C2", // primary color of ant design
  navTheme: "dark", // theme for nav menu
  layout: "sidemenu", // nav menu position: sidemenu or topmenu
  contentWidth: "Fixed", // layout of content: Fluid or Fixed, only works when layout is topmenu
  fixedHeader: false, // sticky header
  fixSiderbar: false, // sticky siderbar
  autoHideHeader: false, //  auto hide header
  colorWeak: false,
  multiTab: false,
  production: process.env.NODE_ENV === "production" && process.env.VUE_APP_PREVIEW !== "true"
};
