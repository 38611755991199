import { loadLanguageAsync } from '@/locales';

export default {
  SetLang({ commit }, lang) {
    return new Promise(resolve => {
      commit('SET_LANG', lang);
      loadLanguageAsync(lang);
      resolve(1);
    });
  },
  SetGlobalStyle({ commit }, style) {
    return new Promise(resolve => {
      commit('SET_GLOBAL_STYLE', style);
      resolve(1);
    });
  }
};
