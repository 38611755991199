
export default {
  sidebar: true,
  device: "desktop",
  theme: "dark",
  layout: "",
  contentWidth: "",
  fixedHeader: false,
  fixSiderbar: false,
  autoHideHeader: false,
  color: null,
  weak: false,
  multiTab: true
};
