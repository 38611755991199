export function triggerWindowResizeEvent() {
  const event = document.createEvent('HTMLEvents');
  event.initEvent('resize', true, true);
  event.eventType = 'message';
  window.dispatchEvent(event);
}

export function generateCodeType(codes) {
  const naviCodes = codes.filter(item => item.navi_type === 1);
  const faciCodes = codes.filter(item => item.navi_type === 0);
  const rules = naviCodes.length > 0 && faciCodes.length > 0;
  return rules
    ? 'mix_code'
    : faciCodes.length > 0
    ? 'spot_code'
    : naviCodes.length > 1
    ? 'voice_code'
    : 'navi_code';
}

export const standardizedParmas = input => {
  return Object.entries(input).reduce((a, [k, v]) => (v ? { ...a, [k]: v } : a), {});
};

export const validateURL = input => {
  // URLs starting with http://, https://, or ftp://
  const pattern1 = /(https?:\/\/[^\s]+)/gi;
  // URLs starting with www. (without // before it, or it'd re-link the ones done above)
  const pattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gi;
  return [pattern1, pattern2].some(item => {
    const regExp = new RegExp(item);
    return regExp.test(input);
  });
};
export const convertToValidSlug = input => {
  return (
    input
      .trim()
      .replace(/\s+/g, '-')
      // eslint-disable-next-line no-useless-escape
      .replace(/[&\/\\#,+()$~%.'":*?<>_[\]{}]/g, '')
      .toLowerCase()
  );
};

export const isValidLATLONG = value => {
  if (!value) return false;
  const rgex = /^[-+]?[0-9]{1,7}(\.[0-9]+)?$/i;
  return rgex.test(value);
};

export const modifyTextForLinks = (textToCheck, className) => {
  // URLs starting with http://, https://, or ftp://
  const replacePattern1 = /(https?:\/\/[^\s]+)/gi;

  // URLs starting with www. (without // before it, or it'd re-link the ones done above)
  const replacePattern2 = /(^|[^/])(www\.[\S]+(\b|$))/gi;

  // Change email addresses to mailto:: links
  const replacePattern3 = /([a-zA-Z0-9._-]+@[a-zA-Z0-9._-]+\.[a-zA-Z0-9_-]+)/gi;

  // number phone
  const replacePattern4 = /([+＋]?\(?(?:[0-9０-９]{2,3})\)?[- －]?)([0-9０-９]{3,4}[- －]?)([0-9０-９]{3,4})/gi;

  return textToCheck
    .replace(replacePattern1, `<a class="${className}" href="$1" target="_blank">$1</a>`)
    .replace(replacePattern2, `$1<a class="${className}" href="http://$2" target="_blank">$2</a>`)
    .replace(replacePattern3, `<a class="${className}" href="mailto:$1">$1</a>`)
    .replace(replacePattern4, `<a class="${className}" href="tel:$1$2$3">$1$2$3</a>`);
};

export const byteCount = input => {
  const exceptString = ' ';
  let byteNumber = 0;
  for (let i = 0; i < input.length; i++) {
    if (exceptString.indexOf(input.charAt(i)) == -1) {
      byteNumber += input.charCodeAt(i) > 128 ? 2 : 1;
    }
  }
  return byteNumber;
};

export const popupCenter = ({
  url,
  title,
  w,
  h,
}) => {
  const dualScreenLeft =
    window.screenLeft !== undefined ? window.screenLeft : window.screenX;
  const dualScreenTop =
    window.screenTop !== undefined ? window.screenTop : window.screenY;

  const width = window.innerWidth
    ? window.innerWidth
    : document.documentElement.clientWidth
    ? document.documentElement.clientWidth
    : screen.width;
  const height = window.innerHeight
    ? window.innerHeight
    : document.documentElement.clientHeight
    ? document.documentElement.clientHeight
    : screen.height;

  const systemZoom = width / window.screen.availWidth;
  const left = (width - w) / 2 / systemZoom + dualScreenLeft;
  const top = (height - h) / 2 / systemZoom + dualScreenTop;
  const newWindow = window.open(
    url,
    title,
    `
    scrollbars=yes,
    width=${w / systemZoom},
    height=${h / systemZoom},
    top=${top},
    left=${left}
    `
  );

  return newWindow;
}

export const urlByAnyData = ({ data, contentType = ' application/json' }) => {
  let url = '';
  // Check data type in any of Blob, ArrayBuffer, or Base64 String
  if (data instanceof Blob) {
    // Blob
    const blob = data;
    url = window.URL.createObjectURL(blob);
  } else if (data instanceof ArrayBuffer) {
    // ArrayBuffer
    const arrayBuffer = data;
    const blob = new Blob([arrayBuffer], { type: contentType });
    url = window.URL.createObjectURL(blob);
  } else if (typeof data === 'string') {
    // Base64 String
    const base64String = data;
    const arrayBuffer = base64ToArrayBuffer(base64String);
    const blob = new Blob([arrayBuffer], { type: contentType });
    url = window.URL.createObjectURL(blob);
  }
  else if (typeof data === 'object') {
    // JSON
    const json = JSON.stringify(data);
    const blob = new Blob([json], { type: contentType });
    url = window.URL.createObjectURL(blob);
  }
  return url;
};

export const base64ToArrayBuffer = base64 => {
  const binaryString = window.atob(base64);
  const binaryLen = binaryString.length;
  const bytes = new Uint8Array(binaryLen);
  for (let i = 0; i < binaryLen; i++) {
    const ascii = binaryString.charCodeAt(i);
    bytes[i] = ascii;
  }
  return bytes.buffer;
};
