export default {
  supportedLangs: (state: any) => {
    return Object.keys(state.supportedLangs).map(key => {
      const obj = state.supportedLangs[key];
      return { ...obj, value: key, disabled: obj.default };
    });
  },
  langByCode: (state: any) => code => {
    return state.supportedLangs[code];
  },
  defaultLang: (state: any, getters) => {
    return getters.supportedLangs.filter(lang => lang.default);
  }
};
