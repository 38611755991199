import Vue from 'vue';
import axios from 'axios';
import message from 'ant-design-vue/es/message';
import { ACCESS_TOKEN } from '@/store/mutation-types';
import i18n from '@/locales';
import store from '@/store';
import router from '@/router';
import { univoiceApi } from '.';

const service = axios.create({
  baseURL: process.env.VUE_APP_SERVER_BASE_URL, // server base_url
  // baseURL: 'http://localhost:3000',
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
});

const err = async error => {
  const originalConfig = error.config;
  if (!error.response) {
    message.error(i18n.t('Network error'));
    return Promise.reject(new Error('Network error'));
  } else {
    const data = error.response.data;
    if (error.response.status === 403) {
      message.error(data.message);
    }
    if (error.response.data.error.code === 401 && !originalConfig._retry) {
      const token = Vue.ls.get(ACCESS_TOKEN);
      store.commit('user/SET_TOKEN', null);
      const result = await univoiceApi.user.refreshAccessToken(token);
      if (result && Object.keys(result).length === 0) {
        message.error(i18n.t('Token is expired. Are you login again?'));
        router.push('/user/login');
        return Promise.reject(new Error('Token is expired'));
      } else {
        store.commit('user/SET_TOKEN', result);
        originalConfig._retry = true;
        return service(originalConfig);
      }
    }
    return Promise.reject(error.response.data.error);
  }
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const isJSON = str => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return false;
  }
};

// request interceptor
service.interceptors.request.use(config => {
  const token = Vue.ls.get(ACCESS_TOKEN);
  if (token) {
    config.headers['Authorization'] = token;
  }
  return config;
}, err);

// response interceptor
service.interceptors.response.use(async response => {
  if (response.status === 204) {
    return {};
  }
  const { data } = response;
  if (data.status === 'PENDING') {
    return data;
  }
  if (data.status === 'OK') {
    return data.data;
  }
  if (data.status === 'NG') {
    if (data.error && data.error.errorCode === 'AUTHORIZATION_REQUIRED') {
      return store.dispatch('user/Logout').then(() => {
        message.error(i18n.t('Token is expired. Are you login again?'));
        router.push('/user/login');
      });
    }
    throw data.error;
  }
  // const check = isJSON(data)

  // if (check) {
  // } else {
  //   return data
  // }

  message.error(i18n.t('Server error, please try again later.'));
  throw new Error('Server error');
}, err);

export default service;
