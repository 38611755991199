import Vue from 'vue'

export default {
  SET_PREF: (state, pref) => {
    state.pref = pref;
  },
  SET_STATE_CITY (state, { entities, ids }) {
    Vue.set(state, 'stateCity', { entities, ids })
  },
  SET_NEW_PREF: (state, newPref) => {
    const newFlatEntities = {};
    Object.keys(newPref).forEach(prefKey => {
      const pref = newPref[prefKey];
      newFlatEntities[prefKey] = pref.prefName;
      if (pref.cities) {
        Object.keys(pref.cities).forEach(cityKey => {
          const city = pref.cities[cityKey];
          newFlatEntities[cityKey] = city.cityName;
          if (city.wards) {
            Object.keys(city.wards).forEach(wardKey => {
              const ward = city.wards[wardKey];
              newFlatEntities[wardKey] = ward.cityName;
            })
          }
        })
      }
    })
    state.newPref = newPref;
    Vue.set(state, 'flatEntities', newFlatEntities);
  }
}
