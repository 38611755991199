import Vue from "vue";
import VueClipboard from "vue-clipboard2";
import VueStorage from "vue-ls";
import moment from "moment";
import VueLazyload from 'vue-lazyload'
import LangSelect from "@/components/tools/LangSelect.vue";
import Ellipsis from "@/components/Ellipsis";
import Draggable from "vuedraggable"
import VueRx from "vue-rx";
import { univoiceApi, tts, lexiconApi } from "@/api"

Vue.use(VueRx)

Vue.prototype.$s = {
  api: univoiceApi,
  tts: tts,
  lexicon: lexiconApi
};

Vue.prototype.$bus = new Vue();

Vue.filter("dayjs", function (dataStr, pattern = "YYYY/MM/DD") {
  if (!dataStr) return "";
  return moment(dataStr).format(pattern);
});

Vue.filter('truncate', function (value, length = 15) {
  if (!value || typeof value !== 'string') { return '' }
  if (value.length <= length) { return value }
  // const REGEX_JAPANESE = /[\u3000-\u303f]|[\u3040-\u309f]|[\u30a0-\u30ff]|[\uff00-\uff9f]|[\u4e00-\u9faf]|[\u3400-\u4dbf]/;
  return value.substring(0, length) + '...';
})

Vue.filter('currency', function (value, lang = 'ja') {
  const label = {
    en: 'JPY',
    ja: '円'
  }
  if (!value) {
    return `0${label[lang]}`
  }
  // const intPartFormat = value.toString().replace(/(\d)(?=(?:\d{3})+$)/g, '$1,')
  const intPartFormat = `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return `${intPartFormat}${label[lang]}`
})

Vue.filter('formatId', function (value, type = "CO") {
  const prefix = `${type}_`;
  return value ? `${prefix}${value.toString().padStart(5, '0')}` : `${prefix}00000`
})

Vue.filter('nl2br', function (text, reg = /\n/g) {
  if (!text) { return '' }
  return text.replace(reg, '<br/>')
})

// global components
Vue.component("LangSelect", LangSelect);
Vue.component("Ellipsis", Ellipsis);
Vue.component("Draggable", Draggable);

Vue.use(VueClipboard);
Vue.use(VueStorage, {
  namespace: "univoice_", // key prefix
  name: "ls", // name variable Vue.[ls] or this.[$ls],
  storage: "local" // storage name session, local, memory
});

import * as VueGoogleMaps from 'vue2-google-maps';

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCWBJOfQF3Jear9a7Q5N9yogYTM-Q6bO6k',
    libraries: 'places,drawing',
    language: 'ja'
  },
  installComponents: true
})

Vue.use(VueLazyload, {
  preLoad: 1.3,
  error: '/img/noimage.svg',
  loading: '/img/loading.svg',
  // filter: {
  //   imagePath (el) {
  //     if (!el.src) {
  //       el.src = ''
  //     } else {
  //       const index = el.src.lastIndexOf('.')
  //       const imgExtend = el.src.substr(index + 1)
  //       const mustBeImage = ['jpg', 'jpeg', 'png', 'svg', 'webp', 'heic'].includes(imgExtend.toLowerCase())
  //       const mustInS3 = [process.env.NUXT_ENV_RECRUITER_BUCKET, process.env.NUXT_ENV_SEEKER_BUCKET].some(path => el.src.includes(path))
  //       if (!mustBeImage || !mustInS3) { el.src = '' }
  //     }
  //   }
  // },
  // lazyComponent: true
})

Vue.filter('nl2br', function (text, reg = /\n/g) {
  if (!text) { return '' }
  return text.replace(reg, '<br/>')
})
