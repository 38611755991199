/**
 * components util
 */

/**
 * 清理空值，对象
 * @param children
 * @returns {*[]}
 */
export function filterEmpty(children = []) {
  return children.filter(c => c.tag || (c.text && c.text.trim() !== ''));
}

/**
 * 获取字符串长度，英文字符 长度1，中文字符长度2
 * @param {*} str
 */
export const getStrFullLength = (str = '') =>
  str.split('').reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0);
    if (charCode >= 0 && charCode <= 128) {
      return pre + 1;
    }
    return pre + 2;
  }, 0);

/**
 * 截取字符串，根据 maxLength 截取后返回
 * @param {*} str
 * @param {*} maxLength
 */
export const cutStrByFullLength = (str = '', maxLength) => {
  let showLength = 0;
  return str.split('').reduce((pre, cur) => {
    const charCode = cur.charCodeAt(0);
    if (charCode >= 0 && charCode <= 128) {
      showLength += 1;
    } else {
      showLength += 2;
    }
    if (showLength <= maxLength) {
      return pre + cur;
    }
    return pre;
  }, '');
};

export const lastIndexOfRegex = function(text, regex, fromIndex) {
  var str = fromIndex ? text.substring(0, fromIndex) : text;
  var match = str.match(regex);
  return match ? str.lastIndexOf(match[match.length - 1]) : -1;
};

export const dateDisplay = params => {
  const { day, month, year, hours, minutes } = params;
  return {
    date: {
      eng: `${day} ${month} ${year}`,
      jpn: `${year}年${month}月${day}日`,
      vie: `Ngày ${day} tháng ${month} năm ${year}`
    },
    dateTime: {
      eng: `${day} ${month} ${year} ${hours}:${minutes}`,
      jpn: `${year}年${month}月${day}日 ${hours}時${minutes}分`,
      vie: `Ngày ${day} tháng ${month} năm ${year}, ${hours} giờ ${minutes} phút`
    }
  };
};

export const getCodeStatusName = function(code) {
  if (code) {
    const actionOfPublished = {
      EDITED: 'PUBLISHED(edited)',
      UPDATING: 'PUBLISHED(updating)'
    };
    return code.status === 'PUBLISHED' && code.action
      ? actionOfPublished[code.action]
      : code.status;
  }
  return '';
};

export const getCodeStatusColorCSS = function (status) {
  if (status) {
    const mapping = {
      published: '#33870a',
      'published(edited)': '#33870a',
      'published(updating)': '#5d5d5d',
      draft: '#8C8C8C',
      pending: '#5d5d5d',
      abolished: '#F5222D',
      failed: '#F5222D'
    };
    return mapping[status.toLowerCase()];
  }
  return '#8C8C8C';
};
