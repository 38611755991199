import axios from 'axios';

class TextToSpeechService {
  constructor() {
    this.tts = axios.create({
      baseURL: 'https://texttospeech.googleapis.com/v1', // api base_url
      timeout: 20000,
      headers: {
        'Content-Type': 'application/json; charset=UTF-8',
        'X-Goog-Api-Key': process.env.VUE_APP_TEXT_TO_SPEECH_API_KEY,
      },
    });
    }
    describeVoices (languageCode) {
      return this.tts
      .request({
        method: 'get',
        url:'/voices',
        params:{languageCode},
      })
    }

  getSynthesizeSpeechAudio(params) {
    return this.tts
      .request({
        method: 'post',
        url:"/text:synthesize",
        data: JSON.stringify(
          params
        ),
      }).catch(error => {throw new Error(error.response.data.error.message)})
  }
}

const tts = new TextToSpeechService();

export default tts;
