// import i18n from "@/locales"

const MESSAGE_STATUS = {
  ALL: 'All',
  WAITING: 'Waiting',
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
  SENDING: 'Sending',
  CANCEL: 'Cancel',
  DONE: 'Done',
  DRAFT: 'Draft'
};

const BASE_TARGET_OPTIONS = [
  // {
  //   value: "gender",
  //   label: 'Gender',//i18n.t("Gender"),
  //   description: 'Gender of user',//i18n.t("Gender of user"),
  //   disable: true
  // },
  // {
  //   value: "age",
  //   label: 'Age',//i18n.t("Age"),
  //   description: 'Age of user',//i18n.t("Age of user"),
  //   disable: false
  // },
  {
    value: 'location',
    label: 'Location', //i18n.t("Location"),
    description: 'Location to which the user belongs', //i18n.t("Location to which the user belongs"),
    disable: false
  },
  {
    value: 'appTarget',
    label: 'App Target', //i18n.t("Location"),
    description: 'User can push to user app', //i18n.t("Location to which the user belongs"),
    disable: false
  }
];

const BASE_ATTACHMENT_OPTIONS = [
  {
    value: 'url',
    label: 'URL',
    description: '',
    disable: false
  },
  {
    value: 'picture',
    label: 'Image',
    description: '',
    disable: false
  },
  {
    value: 'pdf',
    label: 'PDF Attachment',
    description: '',
    disable: false
  }
];

const LANGUAGES = {
  ja: [
    { id: 'jpn', name: '日本語' },
    { id: 'eng', name: '英語' },
    { id: 'chi', name: '簡体字' },
    { id: 'zho', name: '繁体字' },
    { id: 'kor', name: '韓国語' },
    { id: 'fre', name: 'フランス語' },
    { id: 'ger', name: 'ドイツ語' },
    { id: 'spa', name: 'スペイン語' },
    { id: 'ita', name: 'イタリア語' },
    { id: 'por', name: 'ポルトガル語' },
    { id: 'rus', name: 'ロシア語' },
    { id: 'tai', name: 'タイ語' },
    { id: 'vie', name: 'ベトナム語' },
    { id: 'ind', name: 'インドネシア語' },
    { id: 'ara', name: 'アラビア語' },
    { id: 'may', name: 'マレー語' },
    { id: 'hin', name: 'インド言語' },
    { id: 'tgl', name: 'タガログ語' },
    { id: 'dut', name: 'オランダ語' }
  ],
  en: [
    { id: 'jpn', name: 'Japanese' },
    { id: 'eng', name: 'English' },
    { id: 'chi', name: 'Simplified Chinese' },
    { id: 'zho', name: 'Traditional Chinese' },
    { id: 'kor', name: 'Korean' },
    { id: 'fre', name: 'French' },
    { id: 'ger', name: 'German' },
    { id: 'spa', name: 'Spanish' },
    { id: 'ita', name: 'Italian' },
    { id: 'por', name: 'Portuguese' },
    { id: 'rus', name: 'Russian' },
    { id: 'tai', name: 'Thai' },
    { id: 'vie', name: 'Vietnamese' },
    { id: 'ind', name: 'Indonesian' },
    { id: 'ara', name: 'Arabic' },
    { id: 'may', name: 'Malay' },
    { id: 'hin', name: 'Hindi' },
    { id: 'tgl', name: 'Tagalog' },
    { id: 'dut', name: 'Dutch' }
  ]
};

const LANGUAGE_OPTIONS = {
  jpn: 'Japanese',
  eng: 'English',
  chi: 'Simplified Chinese',
  zho: 'Traditional Chinese',
  kor: 'Korean',
  fre: 'French',
  ger: 'German',
  spa: 'Spanish',
  ita: 'Italian',
  por: 'Portuguese',
  rus: 'Russian',
  tai: 'Thai',
  vie: 'Vietnamese',
  ind: 'Indonesian',
  ara: 'Arabic',
  may: 'Malay',
  hin: 'Hindi',
  tgl: 'Tagalog',
  dut: 'Dutch'
};

// ragt --> uv
const RAGT_TO_UV_LANG = {
  ja: 'jpn',
  en: 'eng',
  'zh-Hans': 'chi',
  'zh-Hant': 'zho',
  ko: 'kor',
  fr: 'fre',
  de: 'ger',
  es: 'spa',
  it: 'ita',
  pt: 'por',
  ru: 'rus',
  th: 'tai',
  vi: 'vie',
  in: 'ind',
  ar: 'ara',
  ms: 'may',
  hi: 'hin',
  tl: 'tgl',
  nl: 'dut'
};

// uv --> ragt
const UV_TO_RAGT_LANG = {
  jpn: 'ja',
  eng: 'en',
  chi: 'zh-Hans',
  zho: 'zh-Hant',
  kor: 'ko',
  fre: 'fr',
  ger: 'de',
  spa: 'es',
  ita: 'it',
  por: 'pt',
  rus: 'ru',
  tai: 'th',
  vie: 'vi',
  ind: 'in',
  ara: 'ar',
  may: 'ms',
  hin: 'hi',
  tgl: 'tl',
  dut: 'nl'
};

const APP_TARGET = {
  univoice: 'Uni-Voice',
  univoice_blind: 'Uni-Voice Blind'
};

const EC_ERROR_LEVEL = {
  0: 'L',
  1: 'M',
  2: 'H'
};

const CODE_SIZE = {
  0: 'XS',
  1: 'S',
  2: 'M',
  3: 'L',
  4: 'XL'
};

const LANGUAGES_POLLY = {
  jpn: 'ja-JP',
  eng: 'en-US',
  chi: 'cmn-CN',
  // zho: 'cmn-CN',
  kor: 'ko-KR',
  fre: 'fr-FR',
  ger: 'de-DE',
  spa: 'es-ES',
  ita: 'it-IT',
  por: 'pt-PT',
  rus: 'ru-RU',
  ara: 'arb',
  hin: 'hi-IN',
  dut: 'nl-NL'
};

const LEXICON_NAMES = ['DicP1', 'DicP2', 'DicP3', 'DicP4', 'DicP5'];

const GOOGLE_TTS_LANGUAGES = {
  jpn: 'ja',
  eng: 'en',
  chi: 'zh',
  zho: 'zh',
  kor: 'ko',
  esp: 'eo',
  fra: 'fr',
  deu: 'de',
  fre: 'fr',
  ger: 'de',
  spa: 'es',
  ita: 'it',
  por: 'pt',
  rus: 'ru',
  vie: 'vi',
  ind: 'in',
  ara: 'ar',
  hin: 'hi',
  dut: 'nl',
  may: 'ms',
  tgl: 'tl',
  tai: 'th-TH'
};

const FONT_FAMILY_DEFAULT_EDITOR = {
  jpn: `'Noto Sans JP', Helvetica, arial, sans-serif`,
  eng: `'Roboto', sans-serif`
};

const PROJECT_STATUS = {
  ARCHIVE: 'ARCHIVE',
  ACTIVE: 'ACTIVE'
};

const PAGE_STATE = {
  NONE: 'NONE',
  PRIMARY: 'PRIMARY'
};

const DEFAULT_PORTAL_SETTING = {
  language: 'jpn',
  header_title: '',
  page_template: 'portal',
  is_edited_header_title: false,
  is_set_lang: false,
  is_edited_header_footer: false,
  show_page_title: true,
  show_footer: true,
  show_header: true,
  page_title_color: '#4a86e8',
  page_title_font_size: 'medium', // x-small, small, medium, large, x-large
  page_title_align: 'center', // left, center, right
  bg_color: '#c9daf8',
  heading_color: '#4a86e8',
  project_setting: {
    title_color: '#000000',
    description_color: '#9b9b9b',
    show_thumbnail: true,
    show_description: true
  },
  show_sharing: true,
  created_order: 'DESC',
  item_naming: '',
  search_setting: {
    show_keyword_search: true,
    show_category_search: true,
    search_by: 'mega', // mega | leaf | global
    category_search_title: ''
  },
  mega_setting: {
    search_title_color: '#4a86e8',
    root_bg_color: '#4a86e8',
    root_font_color: '#ffffff',
    sub_bg_color: '#c9daf8',
    sub_font_color: '#ffffff'
  },
  introduction: { show_introduction: false, content: null },
  favicon: '',
  page_logo: {
    logo_url: '',
    show_logo: false,
    logo_caption: ''
  },
  menu_setting: { type: 'icon' }, // icon, menu
  column_setting: { type: 'flexible', number: 1 } // flexible | fixed(1, 2, 3)
};

const DEFAULT_PAGE_SETTING = {
  language: 'jpn',
  header_title: '',
  show_page_title: true,
  page_template: 'card_view',
  category_level: '1',
  align_category_item: 'hor-ver', // hor-ver | ver-hor (horizontal-vertical)
  is_edited_header_title: false,
  is_set_lang: false,
  is_edited_header_footer: false,
  show_header: true,
  show_footer: true,
  page_title_color: '#000000',
  page_title_font_size: 'medium', // x-small, small, medium, large or x-large
  page_title_align: 'center', // left, center or right
  bg_color: '#c9daf8',
  heading_color: '#4a86e8',
  item_setting: {
    title_color: '#4a86e8',
    thumbnail: { show_thumbnail: true, thumbnail_type: 'voice_code' },
    created_date: {
      show_created_date: true,
      format_created_date: 'YYYY/MM/DD'
    },
    category: {
      show_category: true,
      category_hierarchy: 1, // 1, 2, 3
      speak_category_tag: true
    },
    tag: {
      show_tag: true,
      speak_tag: true,
      font_color: '#000000',
      bg_color: '#F0F0F0',
      font_size: 'medium', // small, medium, large
      align: 'left' // left, right
    }
  },
  tts_setting: {
    show_title: true,
    created_date: {
      show_created_date: true,
      format_created_date: 'YYYY/MM/DD'
    },
    font_color: '#000000',
    bg_color: '#F0F0F0',
    thumbnail: { show_thumbnail: true, thumbnail_type: 'voice_code' },
    show_sharing: true,
    show_print_pdf: true,
    category: { show_category: true }
  },
  show_sharing: true,
  show_print_pdf: true,
  order: 'created desc',
  item_naming: '',
  search_setting: {
    show_keyword_search: true,
    show_category_search: true,
    category_search_title: '',
    search_by: 'mega', // mega | leaf | global
    category_hierarchy: 3 // 1, 2, 3
  },
  mega_setting: {
    search_title_color: '#4a86e8',
    root_bg_color: '#4a86e8',
    root_font_color: '#fff',
    sub_bg_color: '#c9daf8',
    sub_font_color: '#fff'
  },
  introduction: { show_introduction: false, content: null },
  favicon: '',
  page_logo: {
    logo_url: '',
    show_logo: false,
    logo_caption: ''
  },
  menu_setting: { type: 'icon' }, // icon or menu
  column_setting: { type: 'flexible', number: 1 } // flexible | fixed(1, 2, 3)
};

const LANGUAGE_CODE_2_LABEL = {
  eng: "English",
  jpn: "Japanese",
  chi: "Simplified Chinese",
  zho: "Traditional Chinese",
  kor: "Korean",
  fre: "French",
  ger: "German",
  spa: "Spanish",
  ita: "Italian",
  por: "Portuguese",
  rus: "Russian",
  tai: "Thai",
  vie: "Vietnamese",
  ind: "Indonesian",
  ara: "Arabic",
  may: "Malay",
  hin: "Hindi",
  tgl: "Tagalog",
  dut: "Dutch"
};

export {
  MESSAGE_STATUS,
  BASE_TARGET_OPTIONS,
  LANGUAGES,
  LANGUAGE_OPTIONS,
  APP_TARGET,
  EC_ERROR_LEVEL,
  CODE_SIZE,
  BASE_ATTACHMENT_OPTIONS,
  LANGUAGES_POLLY,
  LEXICON_NAMES,
  GOOGLE_TTS_LANGUAGES,
  FONT_FAMILY_DEFAULT_EDITOR,
  PROJECT_STATUS,
  DEFAULT_PORTAL_SETTING,
  DEFAULT_PAGE_SETTING,
  PAGE_STATE,
  RAGT_TO_UV_LANG,
  UV_TO_RAGT_LANG,
  LANGUAGE_CODE_2_LABEL
};
