/* eslint-disable no-unused-vars */
import Vue from "vue";
import VueRouter from "vue-router";
import { constantRouterMap } from "@/config/router.config";

// hack router push callback
const Router: any = VueRouter;
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject);
  return originalPush.call(this, location).catch(err => err);
};

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
});
