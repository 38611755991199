var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "user-wrapper" }, [
    _c(
      "div",
      { staticClass: "content-box" },
      [
        _c(
          "a-dropdown",
          [
            _c(
              "span",
              { staticClass: "action ant-dropdown-link user-dropdown-menu" },
              [
                _c(
                  "a-avatar",
                  {
                    staticClass: "avatar",
                    staticStyle: { color: "#fff", backgroundColor: "#2db7f5" },
                    attrs: { size: "small" }
                  },
                  [_vm._v(" " + _vm._s(_vm.nickname[0].toUpperCase()) + " ")]
                ),
                !_vm.isMobile()
                  ? _c("span", [_vm._v(_vm._s(_vm.nickname))])
                  : _vm._e()
              ],
              1
            ),
            _c(
              "a-menu",
              {
                staticClass: "user-dropdown-menu-wrapper",
                attrs: { slot: "overlay" },
                slot: "overlay"
              },
              [
                _c("a-menu-item", { key: "logout" }, [
                  _c(
                    "a",
                    {
                      attrs: { href: "javascript:;" },
                      on: { click: _vm.handleLogout }
                    },
                    [
                      _c("a-icon", { attrs: { type: "logout" } }),
                      _c("span", [_vm._v(_vm._s(_vm.$t("Log out")))])
                    ],
                    1
                  )
                ])
              ],
              1
            )
          ],
          1
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }