//
//
//
//
//
//
//
//
//
//

export default {
  name: 'Logo',
  components: {},
  props: {
    title: {
      type: String,
      default: 'SKG',
      required: false
    },
    showTitle: {
      type: Boolean,
      default: true,
      required: false
    }
  }
};
