import { univoiceApi } from "@/api";

export default {
  GetInfo({ commit }) {
    return univoiceApi.user.me().then((res: any) => {
      const { user = {}, master_data = {} } = res;
      let roles = (user.roles || []).map(r => r.name);
      roles = (roles.length && roles) || ["company"];
      commit("SET_ROLES", roles);
      commit("SET_INFO", user);
      commit("SET_TOKEN", res);
      commit("SET_SETTING", master_data);
      return { roles, user };
    });
  },
  GetMasterData({ commit }, companyId) {
    return univoiceApi.masterData.getMasterData({
      filter: { where: { owner_id: companyId } }
    }).then((res: any) => {
      commit("SET_MASTER_DATA", res)
    })
  },
  UpdateMasterData({ commit }, data) {
    commit("UPDATE_MASTER_DATA", data)
  },
  AddMasterData({ commit }, data) {
    commit("ADD_MASTER_DATA", data)
  },
  Login({ commit }, userInfo) {
    return univoiceApi.user.login(userInfo).then((result: any) => {
      commit("SET_TOKEN", result);
    });
  },

  RefreshToken({ commit, state }) {
    const token = state.token
    commit("SET_TOKEN", null);
    univoiceApi.user.refreshAccessToken(token).then((result: any) => {
      commit("SET_TOKEN", result);
      return result;
    });
  },

  Logout({ commit, state }) {
    return new Promise(resolve => {
      univoiceApi.user
        .logout({ access_token: state.token })
        .then(() => {
          resolve(1);
        })
        .catch(() => {
          resolve(1);
        })
        .finally(() => {
          commit("SET_TOKEN", null);
          commit("SET_ROLES", []);
        });
    });
  }
};
