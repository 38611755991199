/* eslint-disable @typescript-eslint/no-unused-vars */

export default {
  Initializer({ getters, dispatch }) {
    dispatch("resource/GetPref", null, { root: true });
    const companyId = getters["user/userInfo"] && getters["user/userInfo"].company_id
    if (getters["user/userInfo"].company.company_type === "FULL_ACCESS") {
      dispatch("project/fetchProjects", companyId);
      dispatch("billing/fetchBillings", companyId);
      dispatch("user/GetMasterData", companyId);
      dispatch("dictionary/fetchDictionaries", companyId);
    }
  },
  InitializerBeforeLogin({ getters, dispatch }) {
    dispatch("resource/GetPref", null, { root: true });
  }
};
