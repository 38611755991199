import { ACCESS_TOKEN, OAUTH_ACCESS_TOKEN } from "@/store/mutation-types";
import Vue from "vue";

export default {
  SET_TOKEN: (state, result) => {
    if (result) {
      const { id, ttl } = result;
      const expire = ttl * 1000;
      Vue.ls.set(ACCESS_TOKEN, id, expire);
      state.token = result.id;
    } else {
      Vue.ls.remove(ACCESS_TOKEN);
      Vue.ls.remove(OAUTH_ACCESS_TOKEN);
      state.token = "";
    }
  },
  SET_ROLES: (state, roles) => {
    state.roles = roles;
  },
  SET_INFO: (state, info) => {
    state.info = info;
  },
  SET_COMPANY_INFO: (state, info) => {
    state.info.company = info
  },
  SET_SETTING: (state, setting) => {
    state.setting = setting;
  },
  SET_OAUTH_TOKEN: (state, result) => {
    if (result) {
      const { id, ttl } = result;
      const expire = ttl * 1000;
      Vue.ls.set(OAUTH_ACCESS_TOKEN, id, expire);
    } else Vue.ls.remove(OAUTH_ACCESS_TOKEN);
  },
  SET_MASTER_DATA: (state, data) => {
    state.masterData = data.reduce((acc, item) => ({
      ...acc,
      [item?.field]: { id: item.id, value: item, isActive: item?.is_active }
    }), {})
  },
  UPDATE_MASTER_DATA: (state, data) => {
    state.masterData[data.field] = { id: data.id, value: data, isActive: data.is_active }
  },
  ADD_MASTER_DATA: (state, data) => {
    let newMasterData = state.masterData
    newMasterData = { ...newMasterData, [data.field]: { id: data.id, value: data, isActive: data.is_active } }
    Vue.set(state, 'masterData', newMasterData)
  }
};
