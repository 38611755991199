import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import i18n from './locales';

import './plugins/chart';
import './plugins/ag-grid';
import './plugins/ant-design-vue';
import './plugins/use';
import './assets/scss/editor.scss';
import './styles/global.scss';

// library
import bootstrap from './plugins/bootstrap';
import './permission'; // permission control
import './quasar';

Vue.config.productionTip = false;
Vue.config.ignoredElements = ['wc-ragt-editor'];

new Vue({
  router,
  store,
  i18n,
  created: bootstrap,
  render: h => h(App)
}).$mount('#app');
