import Vue from 'vue';
import { univoiceApi } from '@/api';

const initialState = () => ({
	ids: [],
	entities: {}
});

const store = {
	namespaced: true,
	state: {
		...initialState()
	},
	getters: {
		ids: state => state.ids,
		billings: (state, getters) => {
			return getters.ids.map(id => state.entities[id]) || []
		},
		billingById: state => id => state.entities[id]
	},
	actions: {
		fetchBillings({ dispatch }, companyId) {
			const filter = { include: { relation: "company" }}
			univoiceApi.billing.find(companyId, filter)
				.then(billings => {
					dispatch('loadBillings', billings)
				})
		},
		loadBillings({ commit }, payload) {
			return new Promise(resolve => {
				commit("SET", payload);
				resolve({});
			})
		},
		reset({ commit }) {
			return new Promise(resolve => {
				commit("RESET");
				resolve({});
			})
		}
	},
	mutations: {
		SET: (state, payload) => {
			if (Array.isArray(payload)) {
				let newIds = [...state.ids];
				const newEntities = Object.assign({}, state.entities);
				payload.forEach(item => {
					const id = item.id;
					newIds = [...newIds, id];
					newEntities[id] = Object.assign({}, newEntities[id], item);
				});
				const ids = Array.from(new Set(newIds));
				const entities = Object.assign({}, newEntities);

				Vue.set(state, "ids", ids);
				Vue.set(state, "entities", newEntities);
			} else {
				const id = payload.id;
				const newIds = Array.from(new Set([id, ...state.ids]));
				const newEntities = Object.assign({}, state.entities, {
					[id]: Object.assign({}, state.entities[id], payload)
				});
				Vue.set(state, "ids", newIds);
				Vue.set(state, "entities", newEntities);
			}
		},
		RESET: (state) => {
			Object.assign(state, initialState());
		}
	}
}
export default store;

