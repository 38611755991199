import { univoiceApi } from "@/api";

export default {
  GetPref({ commit, state}) {
    if (state.pref) {
      return state.pref;
    }
    return univoiceApi.pref.find().then(({ pref, newPref }) => {
      commit("SET_PREF", pref);

      const ids = Object.keys(pref)
      const groupBy = ids
        .map(id => pref[id])
        .reduce((acc, cur) => {
          const prefName = cur.prefName
          return { ...acc, [prefName]: cur.cities.filter(item => item.bigCityFlag && parseInt(item.bigCityFlag, 10) !== 2) }
        }, {})
      commit('SET_STATE_CITY', {
        entities: groupBy,
        ids: Object.keys(groupBy)
      }),
      commit("SET_NEW_PREF", newPref);
      return pref;
    });
  }
};
