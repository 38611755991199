import Vue from 'vue';
import { univoiceApi } from '@/api';

const initialState = () => ({
  entities: {},
  ids: []
});

const store = {
  namespaced: true,
  state: {
    ...initialState()
  },
  getters: {
    ids: state => state.ids,
    pages: (state, getters) => {
      return getters.ids.map(id => state.entities[id]) || [];
    },
    pagesByTemplate: (state, getters) => template => {
      return getters.pages.filter(page => page.template === template);
    },
    pageById: state => id => state.entities[id]
  },
  actions: {
    fetchPagesByProject({ dispatch }, projectId) {
      univoiceApi.page
        .find(projectId, {
          filter: { where: { status: { neq: 'ARCHIVE' } }, limit: 20, order: 'order_by DESC' }
        })
        .then(pages => {
          dispatch('loadPages', { payload: pages });
        });
    },
    getPageById({ commit, getters }, { projectId, pageId }) {
      if (getters.pageById(pageId)) {
        return getters.pageById(pageId);
      } else {
        return new Promise(resolve => {
          univoiceApi.page.getPageById(projectId, pageId).then(page => {
            commit('SET', { payload: page });
            resolve(page);
          });
        });
      }
    },

    addPage({ commit }, payload) {
      return new Promise(resolve => {
        commit('SET', payload);
        resolve(1);
      });
    },
    updatePageById({ commit }, payload) {
      return new Promise(resolve => {
        commit('UPDATE', payload);
        resolve(1);
      });
    },
    removePage({ commit }, payload) {
      return new Promise(resolve => {
        commit('REMOVE', payload);
        resolve(1);
      });
    },
    loadPages({ commit }, payload) {
      return new Promise(resolve => {
        commit('SET', payload);
        resolve(1);
      });
    },
    reset({ commit }) {
      return new Promise(resolve => {
        commit('RESET');
        resolve(1);
      });
    }
  },
  mutations: {
    SET: (state, { payload }) => {
      if (Array.isArray(payload)) {
        let newIds = [...state.ids];
        const newEntities = Object.assign({}, state.entities);

        payload.forEach(values => {
          const id = values.id;
          newIds = [...newIds, id];
          newEntities[id] = Object.assign({}, newEntities[id], values);
        });

        const ids = Array.from(new Set(newIds));
        const entities = Object.assign({}, newEntities);

        Vue.set(state, 'ids', ids);
        Vue.set(state, 'entities', Object.assign({}, entities));
      } else {
        const id = payload.id;
        const newIds = Array.from(new Set([id, ...state.ids]));
        const newEntities = Object.assign({}, state.entities, {
          [id]: Object.assign({}, state.entities[id], payload)
        });
        Vue.set(state, 'ids', newIds);
        Vue.set(state, 'entities', newEntities);
      }
    },
    REMOVE: (state, payload) => {
      const newEntities = Object.assign({}, state.entities);
      let newIds = [...state.ids];

      if (payload.id in newEntities) {
        delete newEntities[payload.id];
      }
      newIds = newIds.filter(id => id in newEntities);
      Vue.set(state, 'ids', newIds);
      Vue.set(state, 'entities', newEntities);
    },
    UPDATE: (state, payload) => {
      // const updatedMessageBox = payload
      const newEntities = Object.assign({}, state.entities);
      let newIds = [...state.ids];

      if (payload.id in newEntities) {
        newEntities[payload.id] = Object.assign({}, newEntities[payload.id], payload);
      }
      newIds = newIds.filter(id => id in newEntities);
      Vue.set(state, 'ids', newIds);
      Vue.set(state, 'entities', newEntities);
    },
    RESET: state => {
      Object.assign(state, initialState());
    },
    UPDATE_ORDER(state, { id, order_by }) {
      // this action is not affected by the order array, it mean the UI is not re-rendered
      state.entities[id].order_by = order_by;
    }
  }
};

export default store;
