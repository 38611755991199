var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { style: { height: _vm.gridHeight + "px" } },
    [
      _c("ag-grid-vue", {
        staticClass: "ag-theme-alpine",
        style: Object.assign(
          {},
          { width: "100%", height: "100%" },
          _vm.currentGlobalStyle
        ),
        attrs: {
          rowData: _vm.dataSource,
          gridOptions: _vm.options,
          columnDefs: _vm.columns,
          context: _vm.gridContext,
          modules: _vm.modules,
          getRowClass: _vm.getRowClass,
          rowDragManaged: true,
          animateRows: true,
          detailRowAutoHeight: true,
          noRowsOverlayComponent: _vm.noRowsOverlayComponent,
          rowDragText: _vm.rowDragText,
          rowHeight: _vm.rowHeight
        },
        on: {
          "grid-ready": _vm.onGridReady,
          cellClicked: _vm.onCellClicked,
          sortChanged: _vm.sortChanged,
          selectionChanged: _vm.onSelectionChanged,
          "row-drag-enter": _vm.onRowDragEnter,
          "row-drag-move": _vm.onRowDragMove,
          "row-drag-end": _vm.onRowDragEnd,
          "row-drag-leave": _vm.onRowDragLeave
        }
      }),
      _c(
        "div",
        { staticStyle: { "margin-top": "16px", "text-align": "right" } },
        [
          _c("a-pagination", {
            attrs: {
              "show-size-changer": "",
              "page-size-options": ["10", "20", "24", "30", "40", "50"],
              "hide-on-single-page": _vm.hideOnSinglePage,
              "page-size": _vm.pagination.pageSize,
              total: _vm.pagination.total,
              "show-total": function(total) {
                return _vm.$t("Total") + " " + total
              }
            },
            on: {
              "update:pageSize": function($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.pagination, "pageSize", $event)
              },
              change: _vm.onPageChange,
              showSizeChange: _vm.onShowSizeChange
            },
            model: {
              value: _vm.current,
              callback: function($$v) {
                _vm.current = $$v
              },
              expression: "current"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }