var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-breadcrumb",
    { staticClass: "breadcrumb" },
    _vm._l(_vm.breadList, function(item) {
      return _c(
        "a-breadcrumb-item",
        { key: item.name },
        [
          item.name !== _vm.name
            ? _c(
                "router-link",
                { attrs: { to: { path: item.path === "" ? "/" : item.path } } },
                [_vm._v(" " + _vm._s(_vm.$t(item.meta.title)) + " ")]
              )
            : _c("span", [_vm._v(_vm._s(_vm.$t(item.meta.title)))])
        ],
        1
      )
    }),
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }