import Menu from 'ant-design-vue/es/menu';
import Icon from 'ant-design-vue/es/icon';

const { Item, SubMenu } = Menu;

export default {
  name: 'SMenu',
  props: {
    menu: {
      type: Array,
      required: true
    },
    theme: {
      type: String,
      required: false,
      default: 'dark'
    },
    mode: {
      type: String,
      required: false,
      default: 'inline'
    },
    collapsed: {
      type: Boolean,
      required: false,
      default: false
    }
  },
  data() {
    return {
      openKeys: [],
      selectedKeys: [],
      cachedOpenKeys: [],
      count: 11
    };
  },
  computed: {
    rootSubmenuKeys: vm => {
      return vm.menu.map(item => item.path)
    }
  },
  mounted() {
    this.updateMenu();
  },
  watch: {
    collapsed(val) {
      if (val) {
        this.cachedOpenKeys = this.openKeys.concat();
        this.openKeys = [];
      } else {
        this.openKeys = this.cachedOpenKeys;
      }
    },
    $route: function() {
      this.updateMenu();
    }
  },

  methods: {
    onOpenChange(openKeys) {
      if (this.mode === 'horizontal') {
        this.openKeys = openKeys;
        return;
      }
      const latestOpenKey = openKeys.find(key => !this.openKeys.includes(key));
      if (!this.rootSubmenuKeys.includes(latestOpenKey)) {
        this.openKeys = openKeys;
      } else {
        this.openKeys = latestOpenKey ? [latestOpenKey] : [];
      }
    },
    updateMenu() {
      const routes = this.$route.matched;
      const index = routes.findIndex(item => item?.meta?.hidden)
      if (index != -1) {
        this.selectedKeys = [routes[index - 1]?.path]
      } else {
        this.selectedKeys = [routes[routes.length - 1]?.path]
      }
      let openKeys = [];
      if (this.mode === 'inline') {
        openKeys = routes.map(item => item.path);
      }
      this.collapsed ? (this.cachedOpenKeys = openKeys) : (this.openKeys = openKeys);
    },

    renderItem(menu) {
      if (!menu.hidden) {
        return menu.children && !menu.hideChildrenInMenu
          ? this.renderSubMenu(menu)
          : this.renderMenuItem(menu)
      }
      return null;
    },

    renderMenuItem(menu) {
      const { path, name, meta, children, hideChildrenInMenu } = menu
      const props = {
        to: {
          name,
          ...(meta?.query ? { query: meta?.query } : {})
        }
      };
      const attrs = { href: path, target: meta.target };
      if (children && hideChildrenInMenu) {
        menu.children.forEach(item => {
          item.meta = Object.assign(item.meta, { hidden: true });
        });
      }
      return (
        <Item {...{ key: path }}>
          <router-link {...{ props, attrs }}>
            {this.renderIcon(meta.icon)}
            <span>{this.$t(`${meta.title}`)}</span>
          </router-link>
        </Item>
      );
    },

    renderSubMenu(menu) {
      const { path, meta, children, hideChildrenInMenu } = menu
      let itemArr = [];
      if (!hideChildrenInMenu) {
        itemArr = children.map(item => this.renderItem(item))
      }
      return (
        <SubMenu {...{ key: path }}>
          <span slot="title">
            {this.renderIcon(meta.icon)}
            <span>{this.$t(meta.title)}</span>
          </span>
          {itemArr}
        </SubMenu>
      );
    },

    renderIcon(icon) {
      if (!icon) return null;
      const props = {};
      typeof icon === 'object' ? (props.component = icon) : (props.type = icon);
      return <Icon {...{ props }} />;
    }
  },

  render() {
    const { mode, theme, menu } = this;
    const props = {
      mode: mode,
      theme: theme,
      openKeys: this.openKeys
    };
    const on = {
      select: obj => {
        this.selectedKeys = obj.selectedKeys;
        this.$emit('select', obj);
      },
      openChange: this.onOpenChange
    };

    const menuTree = menu.map(item => {
      if (item.hidden) return null;
      return this.renderItem(item);
    });
    return (
      <Menu vModel={this.selectedKeys} {...{ props, on: on }}>
        {menuTree}
      </Menu>
    );
  }
};
