import axios from 'axios';

class LexiconService {
  lexiconService: any;

  constructor() {
    this.lexiconService = axios.create({
      baseURL: process.env.VUE_APP_LEXICON_API,
      timeout: 30000,
      headers: { 'Content-Type': 'application/json' }
    });

    const err = (error) => {
      return Promise.reject(error.response.data);
    };

    // Request interceptor
    this.lexiconService.interceptors.request.use(config => {
      return config
    }, err)

    // Response interceptor
    this.lexiconService.interceptors.response.use(response => {
      if (response.status === 204) {
        return {};
      }
      if ([200, 201].includes(response.status)) {
        return response.data
      }
      throw new Error('Server error');
    }, err);
  }

  getLexicons(companyId) {
    return this.lexiconService.request({
      method: 'get',
      url:`/lexicons?companyId=${companyId}`,
    })
  };

  deleteLexicons(names) {
    return this.lexiconService.request({
      method: 'delete',
      url: '/lexicons',
      data: { names }
    })
  };

  updateLexicon(lexicon) {
    return this.lexiconService.request({
      method: 'put',
      url: '/lexicons',
      data: lexicon
    })
  };

  createLexicon(lexicon) {
    return this.lexiconService.request({
      method: 'post',
      url: '/lexicons',
      data: lexicon
    })
  }

}

const lexiconApi = new LexiconService();
export default lexiconApi;
