//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapActions, mapGetters } from 'vuex';
import { mixin, mixinDevice } from '@/utils/mixin';

export default {
  name: 'UserMenu',
  mixins: [mixin, mixinDevice],
  computed: {
    ...mapGetters('user', ['nickname', 'avatar'])
  },
  methods: {
    ...mapActions('user', ['Logout']),
    handleLogout() {
      const that = this;
      this.$confirm({
        cancelText: this.$tc('Cancel'),
        title: `${that.$tc('Log out')} [${that.nickname}]`,
        onOk() {
          return that
            .Logout()
            .then(() => {
              window.location.reload();
            })
            .catch(err => {
              that.$message.error({
                title: 'Error',
                description: err.message
              });
            });
        },
        onCancel() {}
      });
    }
  }
};
