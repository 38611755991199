var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout",
    { class: ["layout", _vm.device] },
    [
      _vm.isMobile()
        ? _c(
            "a-drawer",
            {
              attrs: {
                placement: "left",
                wrapClassName: "drawer-sider " + _vm.navTheme,
                closable: false,
                visible: _vm.collapsed
              },
              on: { close: _vm.drawerClose }
            },
            [
              _c("side-menu", {
                attrs: {
                  mode: "inline",
                  menus: _vm.menus,
                  theme: _vm.navTheme,
                  collapsed: false,
                  collapsible: true
                },
                on: { menuSelect: _vm.menuSelect }
              })
            ],
            1
          )
        : _vm.isSideMenu()
        ? _c("side-menu", {
            attrs: {
              mode: "inline",
              menus: _vm.menus,
              theme: _vm.navTheme,
              collapsed: _vm.collapsed,
              collapsible: true
            }
          })
        : _vm._e(),
      _c(
        "a-layout",
        {
          class: [_vm.layoutMode, "content-width-" + _vm.contentWidth],
          style: { paddingLeft: _vm.contentPaddingLeft, minHeight: "100vh" }
        },
        [
          _c("global-header", {
            attrs: {
              mode: _vm.layoutMode,
              menus: _vm.menus,
              theme: _vm.navTheme,
              collapsed: _vm.collapsed,
              device: _vm.device
            },
            on: { toggle: _vm.toggle }
          }),
          _c(
            "a-layout-content",
            {
              style: {
                height: "100%",
                margin: "20px 20px 0",
                paddingTop: _vm.fixedHeader ? "64px" : "0"
              }
            },
            [
              _vm.multiTab ? _c("multi-tab") : _vm._e(),
              _c(
                "transition",
                { attrs: { name: "page-transition" } },
                [_c("route-view")],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }