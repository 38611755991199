var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a-layout-sider",
    {
      class: [
        "sider",
        _vm.isDesktop() ? null : "shadow",
        _vm.theme,
        _vm.fixSiderbar ? "ant-fixed-sidemenu" : null
      ],
      attrs: { width: "256px", collapsible: _vm.collapsible, trigger: null },
      model: {
        value: _vm.collapsed,
        callback: function($$v) {
          _vm.collapsed = $$v
        },
        expression: "collapsed"
      }
    },
    [
      _c("logo", { attrs: { title: _vm.$t("Uni-Voice for UD") } }),
      _c("br"),
      _c("s-menu", {
        staticStyle: { flex: "1" },
        attrs: {
          collapsed: _vm.collapsed,
          menu: _vm.menus,
          theme: _vm.theme,
          mode: _vm.mode
        },
        on: { select: _vm.onSelect }
      }),
      _vm.isDesktop() && _vm.sidebarOpened
        ? _c("div", { staticClass: "sider-copyright" }, [
            _c("span", [
              _vm._v(
                " © " +
                  _vm._s(_vm.now) +
                  " " +
                  _vm._s(_vm.$t("Uni-Voice for UD")) +
                  " "
              ),
              _vm.env !== "production"
                ? _c("span", [_vm._v("(" + _vm._s(_vm.env) + ")")])
                : _vm._e()
            ])
          ])
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }