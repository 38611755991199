//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mixin, mixinDevice } from '@/utils/mixin';
import { mixin as langMixin } from '@/mixins/i18n-mixin';
import { AgGridVue } from '@ag-grid-community/vue';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import NoRowsOverlay from './NoRowsOverlay.vue';
import CustomHeaderVue from './CustomHeader.vue';

const checkboxCol = {
  headerCheckboxSelection: true,
  headerCheckboxSelectionFilteredOnly: true,
  checkboxSelection: params => {
    const { data } = params;
    return !data.disabled;
  },
  pinned: 'left',
  width: 40
};

export default {
  mixins: [mixin, mixinDevice, langMixin],
  components: {
    'ag-grid-vue': AgGridVue,
    // agColumnHeader is auto registered by ag-grid
    agColumnHeader: CustomHeaderVue,
    NoRowsOverlay
  },
  props: {
    gridContext: {
      type: Object,
      default: () => {}
    },
    rowData: {
      type: Array,
      default: () => []
    },
    columnDefs: {
      type: Array,
      default: () => []
    },
    gridOptions: {
      type: Object,
      default: () => {}
    },
    enableCheckbox: {
      type: Boolean,
      default: () => false
    },
    pagination: {
      type: Object,
      default: () => {
        return {
          pageSize: 20,
          current: 1,
          total: 0
        };
      }
    },
    hideOnSinglePage: { type: Boolean, default: () => true },
    gridName: {
      type: String,
      default: ''
    },
    getRowClass: {
      type: Function,
      default: () => {
        return '';
      }
    },
    rowDragText: {
      type: Function,
      default: () => {
        return '';
      }
    },
    rowHeight: {
      // row height is for prevent animation when row is init, it anoying,
      // if not provide, it will use default value, and after auto height is calculated, it will change to auto height
      type: Number,
      default: 40
    },
    gridHeight: {
      type: Number,
      default: 700
    }
  },
  data() {
    return {
      modules: [ClientSideRowModelModule],
      current: 1,
      noRowsOverlayComponent: null
    };
  },
  computed: {
    dataSource() {
      return this.rowData;
    },
    columns() {
      // This is a magic
      const columnsWidth = this.$ls.get(`${this.gridName}.grid.columns.width`);
      let newColumnDefs = [...this.columnDefs];
      if (columnsWidth) {
        newColumnDefs = this.columnDefs.map(col => {
          return {
            ...col,
            width: columnsWidth[col.field] || col.width
          };
        });
      }
      return ((this.enableCheckbox && [checkboxCol]) || []).concat(...newColumnDefs);
    },
    options() {
      const that = this;
      return {
        suppressScrollOnNewData: true,
        suppressRowClickSelection: true,
        suppressCellFocus: true,
        enableCellTextSelection: true,
        enableCellChangeFlash: true,
        rowSelection: 'multiple',
        domLayout: 'normal',
        defaultColDef: {
          resizable: true,
          sortable: false,
          autoHeight: true,
          minWidth: 40,
          width: 200,
          cellClass: 'cell-wrap-text',
          cellStyle: {
            'padding-left': '5px',
            'padding-right': '5px'
          }
        },
        ...this.gridOptions,
        onColumnResized: function(event) {
          const columnsWidth = that.$ls.get(`${that.gridName}.grid.columns.width`, {});
          const newColumnsWidth = {
            ...columnsWidth,
            [event.column.colId]: event.column.actualWidth
          };
          that.$ls.set(`${that.gridName}.grid.columns.width`, newColumnsWidth);
        }
      };
    }
  },
  created() {
    this.current = this.pagination.current;
    this.noRowsOverlayComponent = 'NoRowsOverlay';
  },

  methods: {
    onRowDragEnter(e) {
      this.$emit('onRowDragEnter', e);
    },
    onRowDragMove(e) {
      this.$emit('onRowDragMove', e);
    },
    onRowDragEnd(e) {
      this.$emit('onRowDragEnd', e);
    },
    onRowDragLeave(e) {
      this.onRowDragEnd(e);
    },
    onPageChange(page, pageSize) {
      this.$emit('onPageChange', { page, pageSize });
    },
    onShowSizeChange(current, pageSize) {
      this.$emit('onPageChange', { page: current, pageSize });
    },
    onSelectionChanged(e) {
      this.$emit('selectionChanged', e);
    },
    sortChanged(e) {
      console.log('sortChanged:', e);
    },
    onCellClicked(e) {
      this.$emit('cellClicked', e);
    },
    refresh(params) {
      this.params = params;
      return true;
    },
    onGridReady(params) {
      this.gridApi = params.api;
      this.columnApi = params.columnApi;
      this.$emit('onGridReady', params);
    },
    deselectAll() {
      this.gridApi.deselectAll();
    }
  }
};
