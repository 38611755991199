var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "logo" },
    [
      _c("router-link", { attrs: { to: { path: "/" } } }, [
        _c("img", {
          attrs: { src: require("@/assets/logo.png"), alt: "logo" }
        }),
        _vm.showTitle ? _c("h1", [_vm._v(_vm._s(_vm.title))]) : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }