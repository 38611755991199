//
//
//
//
//
//

import Breadcrumb from '@/components/tools/Breadcrumb';

export default {
  name: 'PageHeader',
  components: {
    's-breadcrumb': Breadcrumb
  },
  props: {},
  data() {
    return {};
  }
};
