//
//
//
//
//
//
//
//
//
//
//

export default {
  data() {
    return {
      name: '',
      breadList: []
    };
  },
  created() {
    this.getBreadcrumb();
  },
  methods: {
    mapParams(url, { routeParams = {} }) {
      Object.keys(routeParams).forEach(key => {
        url = url.replace(new RegExp(':' + key + '(/|$)', 'g'), routeParams[key] + '$1');
      });
      return url;
    },
    getBreadcrumb() {
      this.breadList = [];
      this.name = this.$route.name;
      this.$route.matched.forEach(item => {
        if (item.meta?.title) {
          this.breadList.push({
            ...item,
            path: this.mapParams(item.path, { routeParams: this.$route.params })
          });
        }
      });
    }
  },
  watch: {
    $route() {
      this.getBreadcrumb();
    }
  }
};
