export default {
  supportedLangs: {
    ja: {
      label: "Japanese",
      default: true
    },
    en: {
      label: "English",
    },
    ko: {
      label: "Korean"
    },
    zh: {
      label: "Chinese(Simplified)"
    }
  }
}