var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      style: !_vm.$route.meta.hiddenHeaderContent
        ? "margin: -24px -24px 0px;"
        : null
    },
    [
      _c(
        "div",
        { staticClass: "content-container" },
        [
          _c(
            "a-card",
            { attrs: { "body-style": { padding: "12px" } } },
            [
              _vm.multiTab
                ? _c("keep-alive", [_c("router-view", { ref: "content" })], 1)
                : _c("router-view", { ref: "content" })
            ],
            1
          )
        ],
        1
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }