var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      class: [_vm.enableClickPointer && "cursor-pointer"],
      on: { click: _vm.handleClickHeader }
    },
    [
      _c("div", { staticClass: "customHeaderLabel" }, [
        _vm._v(_vm._s(_vm.$t("" + _vm.params.displayName)))
      ]),
      _vm.params.enableSorting
        ? _c(
            "div",
            { staticClass: "sort-section active" },
            [
              _vm.sortState === "asc"
                ? _c("a-icon", {
                    staticStyle: { color: "#e46d55" },
                    attrs: { type: "sort-ascending" }
                  })
                : _vm.sortState === "desc"
                ? _c("a-icon", {
                    staticStyle: { color: "#e46d55" },
                    attrs: { type: "sort-descending" }
                  })
                : _vm.colDef.unSortIcon
                ? _c("span", [
                    _c(
                      "svg",
                      {
                        staticClass: "icon icon-tabler icon-tabler-arrows-sort",
                        attrs: {
                          xmlns: "http://www.w3.org/2000/svg",
                          width: "16",
                          height: "16",
                          viewBox: "0 0 24 24",
                          "stroke-width": "2",
                          stroke: "currentColor",
                          fill: "none",
                          "stroke-linecap": "round",
                          "stroke-linejoin": "round"
                        }
                      },
                      [
                        _c("path", {
                          attrs: {
                            stroke: "none",
                            d: "M0 0h24v24H0z",
                            fill: "none"
                          }
                        }),
                        _c("path", { attrs: { d: "M3 9l4 -4l4 4m-4 -4v14" } }),
                        _c("path", {
                          attrs: { d: "M21 15l-4 4l-4 -4m4 4v-14" }
                        })
                      ]
                    )
                  ])
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }