//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/* eslint-disable no-undef */
import Vue from 'vue';

export default Vue.extend({
  computed: {
    colDef() {
      return this.params.column.colDef;
    },
    enableClickPointer() {
      return this.params.enableSorting;
    }
  },
  data() {
    return {
      sortState: null
    };
  },
  mounted() {
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.onSortChanged();
  },
  methods: {
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },
    handleClickHeader() {
      if (this.enableClickPointer) {
        this.onSortRequested();
      }
    },
    onSortChanged() {
      const { order } = this.$route.query;
      const [colId, orderType] = order ? order.split(' ') : [];
      if (colId !== this.params.column.colId) {
        this.sortState = null;
        return;
      }
      if (orderType === 'asc' || orderType === undefined) {
        this.sortState = 'asc';
      } else if (orderType === 'desc') {
        this.sortState = 'desc';
      } else {
        this.sortState = null;
      }
    },

    onSortRequested(order, _event) {
      this.nextSortState();
      const query = {
        ...this.$route.query
      };
      if (this.sortState === null) {
        delete query.order;
      } else {
        query.order = [this.params.column.colId, this.sortState].join(' ');
      }

      // this.params.setSort(order, event.shiftKey);
      this.$router.replace({ query });
    },
    nextSortState() {
      if (this.sortState === 'asc') {
        this.sortState = 'desc';
      } else if (this.sortState === 'desc') {
        this.sortState = null;
      } else {
        this.sortState = 'asc';
      }
    }
  }
});
